<template>
    <form-modal
        ref="formModal"
        :v="$v.tenantUserModal"
        :title="i18nUserBusinessUnit"
        size="lg"
    >
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-row>
                    <b-col sm="6">
                        <b-form-group
                            :label="i18nName"
                            label-for="modal-name"
                            class="label-pdv"
                        >
                            <b-form-input
                                id="modal-name"
                                v-model="tenantUserModal.name"
                                class="input-pdv-blue"
                                name="name"
                                aria-describedby="input-1-live-feedback"
                                disabled
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group
                            :label="i18nEmail"
                            label-for="modal-email"
                            class="label-pdv"
                        >
                            <b-form-input
                                id="modal-email"
                                v-model="tenantUserModal.email"
                                class="input-pdv-blue"
                                name="email"
                                aria-describedby="input-1-live-feedback"
                                disabled
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <topic-header
                    :caption="getI18n('COMMON', 'intelligence')"
                    icon="fe-grid"
                />
                <b-row>
                    <b-col md="4">
                        <b-form-group
                            :label="i18nServingCostModule"
                            label-for="modal-serving-cost-module"
                            class="label-pdv"
                        >
                            <b-form-checkbox
                                id="modal-serving-cost-module"
                                v-model="tenantUserModal.cost_of_serving_module"
                                name="cost_of_serving_module"
                                switch
                                size="lg"
                                :disabled="submitLoading"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group
                            :label="i18nCommercialModule"
                            label-for="modal-commercial-module"
                            class="label-pdv"
                        >
                            <b-form-checkbox
                                id="modal-commercial-module"
                                v-model="tenantUserModal.commercial_module"
                                name="commercial_module"
                                switch
                                size="lg"
                                :disabled="submitLoading"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>
        </template>
        <template v-slot:footer>
            <cancel-button
                ref="cancelButton"
                child-class="col-sm-4 float-left"
                :disabled="submitLoading"
                @onClick="handleCancel"
            />
            <save-button
                ref="okButton"
                child-class="col-sm-4 float-right ml-3"
                :busy="submitLoading"
                @onClick="handleOk"
            />
        </template>
    </form-modal>
</template>
<script>
import FormModal from '@components/FormModal'
import validationMixin from '@src/mixins/validation'
import swalFeedback from '@src/mixins/swalFeedback'
import UsersService from '@src/services/admin/UsersService'
import TopicHeader from '@src/components/TopicHeader.vue'
import CancelButton from '@/src/components/CancelButton'
import SaveButton from '@/src/components/SaveButton'

const i18nCommon = 'COMMON'
const i18nKey = 'TENANT'

export default {
    components: {
        FormModal,
        TopicHeader,
        CancelButton,
        SaveButton,
    },
    mixins: [validationMixin, swalFeedback],
    data() {
        return {
            i18nCommon,
            i18nKey,
            tenantUserModal: {},
            submitLoading: false,
        }
    },
    validations: {
        tenantUserModal: {
            cost_of_serving_module: {
                required: true,
            },
            commercial_module: {
                required: true,
            },
        },
    },
    computed: {
        i18nUserBusinessUnit() {
            return `${this.getI18n('USERS.TITLES.user')} (${this.getI18n('COMMON.business_unit')})`
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nEmail() {
            return this.getI18n(i18nCommon, 'email')
        },
        i18nPassword() {
            return this.getI18n(i18nCommon, 'password')
        },
        i18nPasswordConfirmation() {
            return this.getI18n(i18nCommon, 'password_confirmation')
        },
        i18nTenant() {
            return this.getI18n(i18nKey, 'TITLES.tenant')
        },
        i18nServingCostModule() {
            return this.getI18n('BUSINESS_UNITS', 'TITLES.serving_cost_module')
        },
        i18nCommercialModule() {
            return this.getI18n('BUSINESS_UNITS', 'TITLES.commercial_module')
        },
    },
    methods: {
        showModal(user) {
            this.submitLoading = false
            this.tenantUserModal = user
            this.$nextTick(() => this.$refs.formModal.show())
            this.resetValidation()
        },

        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
        },

        cleanModal() {
            this.replaceModalData({
                id: 0,
                email: '',
                password: '',
                password_confirmation: '',
            })
            this.resetValidation()
        },

        replaceModalData(data) {
            Object.assign(this.tenantUserModal, data)
        },

        handleCancel(event) {
            this.resetValidation()
            this.$refs.formModal.hide()
        },

        handleOk(event) {
            this.$v.tenantUserModal.$touch()
            if (!this.$v.tenantUserModal.$invalid && !this.submitLoading) {
                this.submitLoading = true
                this.handleSubmit(event)
            }
        },
        updateUserAdminPassword() {
            const operation = UsersService.update(this.tenantUserModal.id, this.tenantUserModal)

            return operation
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    this.unsuccessfulOperation()
                    return error
                })
        },

        async handleSubmit(event) {
            const response = await this.updateUserAdminPassword()

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$refs.formModal.hide()
                this.successfulOperation()
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            }
            this.submitLoading = false
        },
        successfulOperation() {
            this.resetValidation()
            this.positiveFeedback(this.i18nTenant, 'edited')
        },
        unsuccessfulOperation() {
            this.negativeFeedback()
        },
    },
}
</script>
