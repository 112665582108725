<template>
    <form-modal
        ref="formModal"
        :v="$v.tenantChangePasswordModal"
        :title="i18nChangePassword"
    >
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-form-row>
                    <b-col sm="12">
                        <b-form-group
                            :label="i18nEmail"
                            label-for="modal-email"
                            class="label-pdv"
                        >
                            <b-form-input
                                id="modal-email"
                                v-model="tenantChangePasswordModal.email"
                                class="input-pdv-blue"
                                name="email"
                                aria-describedby="input-1-live-feedback"
                                type="text"
                                disabled
                            />
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <b-form-row>
                    <b-col sm="12">
                        <b-form-group
                            :label="i18nPassword"
                            label-for="modal-password"
                            class="required label-pdv"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_password_length')"
                        >
                            <b-form-input
                                id="modal-password"
                                v-model="tenantChangePasswordModal.password"
                                class="input-pdv-blue"
                                name="password"
                                aria-describedby="input-1-live-feedback"
                                type="password"
                                :state="validateField('password', 'tenantChangePasswordModal')"
                                :placeholder="i18nPassword"
                                autofocus
                                autocomplete="new-password"
                                @input="clearResponseError('password', 'tenantChangePasswordModal')"
                                @blur="$v.tenantChangePasswordModal.password.$touch;"
                            />
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <b-form-row>
                    <b-col sm="12">
                        <b-form-group
                            :label="i18nPasswordConfirmation"
                            label-for="modal-password-confirmation"
                            class="required label-pdv"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_password_match')"
                        >
                            <b-form-input
                                id="modal-password-confirmation"
                                v-model="tenantChangePasswordModal.password_confirmation"
                                class="input-pdv-blue"
                                name="password"
                                aria-describedby="input-1-live-feedback"
                                type="password"
                                :state="validateField('password_confirmation', 'tenantChangePasswordModal')"
                                :placeholder="i18nPasswordConfirmation"
                                autocomplete="new-password"
                                @input="clearResponseError('password_confirmation', 'tenantChangePasswordModal')"
                                @blur="$v.tenantChangePasswordModal.password_confirmation.$touch;"
                            />
                        </b-form-group>
                    </b-col>
                </b-form-row>
            </form>
        </template>
        <template v-slot:footer>
            <b-button
                ref="cancelButton"
                class="col-sm-5 float-left btn btn-pdv-gradient-blue"
                @click="handleCancel"
                >{{ getI18n('COMMON.back') }}
            </b-button>
            <b-button
                ref="okButton"
                class="col-sm-5 float-right btn btn-pdv-gradient-green"
                @click="handleOk"
                >
                {{ getI18n('COMMON.update') }}
            </b-button>
        </template>
    </form-modal>
</template>
<script>
import FormModal from '@components/FormModal'
import { minLength, sameAs } from 'vuelidate/lib/validators'
import validationMixin from '@src/mixins/validation'
import swalFeedback from '@src/mixins/swalFeedback'
import TenantService from '@src/services/TenantService'

const i18nCommon = 'COMMON'
const i18nKey = 'TENANT'

export default {
    components: {
        FormModal,
    },
    mixins: [validationMixin, swalFeedback],
    props: {
        modal: {
            type: Object,
            default: () => {
                return {
                    id: 0,
                    email: '',
                    password: '',
                    password_confirmation: '',
                }
            },
        },
    },
    data() {
        return {
            i18nCommon,
            i18nKey,
            tenantChangePasswordModal: this.modal,
            submitLoading: false,
        }
    },
    validations: {
        tenantChangePasswordModal: {
            email: {
                required: true,
            },
            password: {
                required: true,
                minLength: minLength(6),
            },
            password_confirmation: {
                required: true,
                sameAsPassword: sameAs('password'),
            },
        },
    },
    computed: {
        i18nChangePassword() {
            return this.getI18n(i18nKey, 'TITLES.change_password')
        },
        i18nEmail() {
            return this.getI18n(i18nCommon, 'email')
        },
        i18nPassword() {
            return this.getI18n(i18nCommon, 'password')
        },
        i18nPasswordConfirmation() {
            return this.getI18n(i18nCommon, 'password_confirmation')
        },
        i18nTenant() {
            return this.getI18n(i18nKey, 'TITLES.tenant')
        },
    },
    methods: {
        showModal() {
            this.submitLoading = false
            this.tenantChangePasswordModal = this.modal
            this.$nextTick(() => this.$refs.formModal.show())
            this.resetValidation()
        },

        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
        },

        cleanModal() {
            this.replaceModalData({
                id: 0,
                email: '',
                password: '',
                password_confirmation: '',
            })
            this.resetValidation()
        },

        replaceModalData(data) {
            Object.assign(this.tenantChangePasswordModal, data)
        },

        handleCancel(event) {
            this.resetValidation()
            this.$refs.formModal.hide()
        },

        handleOk(event) {
            this.$v.tenantChangePasswordModal.$touch()
            if (!this.$v.tenantChangePasswordModal.$invalid && !this.submitLoading) {
                this.submitLoading = true
                this.handleSubmit(event)
            }
        },
        updateUserAdminPassword() {
            const operation = TenantService.updateAdminByEmail(this.tenantChangePasswordModal.id, this.tenantChangePasswordModal)

            return operation
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    this.unsuccessfulOperation()
                    return error
                })
        },

        async handleSubmit(event) {
            const response = await this.updateUserAdminPassword()

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$refs.formModal.hide()
                this.successfulOperation()
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            }
            this.submitLoading = false
        },
        successfulOperation() {
            this.resetValidation()
            this.positiveFeedback(this.i18nTenant, 'edited')
        },
        unsuccessfulOperation() {
            this.negativeFeedback()
        },
    },
}
</script>
