import axios from 'axios'

import BaseService from '@src/services/BaseService'

class TenantService extends BaseService {
    constructor() {
        super('tenants')
    }

    updateAdminByEmail(id, params) {
        return axios.post(`${this.getUrl()}/${id}/update-by-email`, params)
    }
}

export default new TenantService()
