<script>
import ApiModeVuetable from '@src/components/ApiModeVuetable'
import PageHeader from '@/src/components/PageHeader.vue'
import Layout from '@layouts/main.vue'
import TenantService from '@src/services/TenantService'
import Pagination from '@src/mixins/pagination.vue'
import Shared from '@src/mixins/shared.vue'
import swalFeedback from '@src/mixins/swalFeedback'
import vuetableFormatter from '@src/mixins/vuetableFormatters'
import tenantModal from '@src/router/views/tenant/tenantModal'
import validationMixin from '@src/mixins/validation.vue'
import FilterBar from '@src/components/FilterBar'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import { debounce } from 'lodash'

const i18nCommon = 'COMMON'
const i18nKey = 'TENANT'

export default {
    metaInfo() {
        return {
            title: this.i18nTenant(2),
        }
    },
    components: {
        ApiModeVuetable,
        PageHeader,
        Layout,
        tenantModal,
        FilterBar,
        MoreTableButton,
    },
    mixins: [Pagination, Shared, swalFeedback, validationMixin, vuetableFormatter],
    data() {
        return {
            i18nCommon,
            i18nKey,
            modalIsEdit: false,
            modal: {
                id: 0,
                name: '',
                limit_units: 0,
                limit_users: 0,
                base_day: 1,
                uuid: '',
                default_timezone: '',
                is_photo_checkin: false,
                is_photo_checkout: false,
                is_add_pdv: false,
                is_add_pdv_to_business_unit: false,
                is_add_user_to_business_unit: false,
                is_minimum_distance_checkin: false,
                minimum_distance_checkin: 0,
                is_minimum_distance_checkout: false,
                minimum_distance_checkout: 0,
                is_geotag_user: false,
                is_interval: false,
                is_chat: false,
                is_required_checkin: false,
                email: '',
                password: '',
                password_confirmation: '',
                timezone: '',
                minimum_time_store: 0,
                is_alter_password_and_permissions: false,
                is_add_competitor_to_mix: false,
                out_of_stock: false,
                new_pos_btn: false,
            },
            data: [],
            additionalParameters: {},
            perPage: 10,
            css: {},
            fields: [
                {
                    name: 'name',
                    title: this.getI18n(i18nCommon, 'name'),
                    sortField: 'name',
                },
                {
                    name: 'default_timezone',
                    title: this.getI18n(i18nCommon, 'timezone'),
                    sortField: 'default_timezone',
                    formatter: this.formatTimezone,
                },
                {
                    name: 'created_at',
                    title: this.getI18n(i18nCommon, 'created_at'),
                    sortField: 'created_at',
                },
                {
                    name: 'updated_at',
                    title: this.getI18n(i18nCommon, 'updated_at'),
                    sortField: 'updated_at',
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
            filterText: '',
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.tenant',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
    },
    watch: {
        filterText: debounce(function (searchText) {
            this.additionalParameters.search = ''
            if (searchText && searchText.length > 0) {
                this.additionalParameters.search = `name:${searchText}`
            }
            this.refreshTable()
        }, 1000),
    },
    methods: {
        fetch(url, params) {
            return TenantService.fetchVuetable(url, params)
        },

        i18nTenant(modifier) {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.tenant',
                modifier,
            })
        },

        cleanModal() {
            this.$refs.tenantModal.replaceModalData({
                id: 0,
                name: '',
                limit_units: 0,
                limit_users: 0,
                base_day: 1,
                uuid: '',
                default_timezone: '',
                is_photo_checkin: false,
                is_photo_checkout: false,
                is_add_pdv: false,
                is_add_pdv_to_business_unit: false,
                is_add_user_to_business_unit: false,
                is_minimum_distance_checkin: false,
                minimum_distance_checkin: 0,
                is_minimum_distance_checkout: false,
                minimum_distance_checkout: 0,
                is_geotag_user: false,
                is_interval: false,
                is_chat: false,
                is_required_checkin: false,
                admin_email: '',
                password: '',
                password_confirmation: '',
                timezone: '',
                minimum_time_store: 0,
                is_alter_password_and_permissions: false,
                is_add_competitor_to_mix: false,
                out_of_stock: false,
                new_pos_btn: false,
            })
        },

        showModal() {
            this.$nextTick(() => this.$refs.tenantModal.showModal())
        },

        openModalToCreate() {
            this.modalIsEdit = true
            this.cleanModal()
            this.showModal()
        },

        openModalToEdit(tenant) {
            this.modalIsEdit = false
            this.$refs.tenantModal.replaceModalData(tenant)
            this.showModal()
        },

        refreshTable() {
            this.$refs.vuetable.refresh()
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <div class="row mt-3 mb-3">
                <div class="col-sm-6">
                    <b-btn
                        class="col-sm-6 btn btn-pdv-gradient-blue"
                        @click="openModalToCreate"
                    >
                        <i class="fe-plus-circle"></i>
                        {{ getI18n(i18nKey, 'BUTTONS.new_tenant') }}
                    </b-btn>
                </div>
                <div class="col-sm-6 form-inline justify-content-end">
                    <filter-bar v-model="filterText"/>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nTenant(2)" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="fields"
                        :per-page="perPage"
                        :additional-parameters="additionalParameters">
                        <template
                            slot="actions"
                            slot-scope="props">
                            <div class="table-button-container">
                                <more-table-button @onClick="openModalToEdit(props.rowData)" />
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <tenant-modal
            ref="tenantModal"
            :modal="modal"
            :modal-is-edit="modalIsEdit"
            @refresh="refreshTable"
        />
    </Layout>
</template>

<style>
    .content-page {
        margin-left: 0px !important;
    }

    .left-side-menu {
        display: none;
    }

    .navbar-custom {
        background-color: #3E4347;
        color: #fff;
    }

    .footer {
        background-color: transparent;
        left: 0;
    }
</style>
