<template>
    <form-modal
        ref="formModal"
        :v="$v.tenantModal"
        :title="i18nTitleModal"
        :in-editing="isEdit"
        size="xl"
        :handle-close="handleCancel"
    >
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <tenantChangePasswordModal
                    ref="tenantChangePasswordModal"
                    :modal="changePasswordModal"
                />
                <tenantUserModal ref="tenantUserModal" />
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nTenant"
                    :operation="modalOperation"
                />
                <b-tabs
                    id="tenant-tabs"
                    v-model="tabIndex"
                    class="tabs-pdv">
                    <b-tab :title="i18nInformation">
                        <topic-header
                            :caption="getI18n('TENANT', 'TITLES.general')"
                        />
                        <b-form-row>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nName"
                                    label-for="modal-name"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_name')"
                                >
                                    <b-form-input
                                        id="modal-name"
                                        v-model.trim="$v.tenantModal.name.$model"
                                        class="input-pdv-blue"
                                        name="name"
                                        aria-describedby="input-1-live-feedback"
                                        type="text"
                                        :state="validateField('name', 'tenantModal')"
                                        :placeholder="i18nName"
                                        :disabled="!isEdit || submitLoading"
                                        autofocus
                                        autocomplete="off"
                                        @input="clearResponseError('name', 'tenantModal')"
                                        @blur="$v.tenantModal.name.$touch"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nEmail"
                                    label-for="modal-admin_email"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_email')"
                                >
                                    <b-form-input
                                        id="modal-admin_email"
                                        v-model="$v.tenantModal.admin_email.$model"
                                        class="input-pdv-blue"
                                        name="admin_email"
                                        aria-describedby="input-1-live-feedback"
                                        type="text"
                                        :state="validateField('admin_email', 'tenantModal')"
                                        :placeholder="i18nEmail"
                                        :disabled="isTenantCreated || submitLoading"
                                        autocomplete="off"
                                        @input="clearResponseError('admin_email', 'tenantModal')"
                                        @blur="$v.tenantModal.admin_email.$touch;"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nTimezone"
                                    class="required label-pdv"
                                    label-for="tenant_admin-timezone"
                                >
                                    <multi-select
                                        id="tenant_admin-timezone"
                                        v-model="timezone"
                                        :options="timezones"
                                        label="label"
                                        :searchable="true"
                                        :disabled="!isEdit || submitLoading"
                                        @input="changeTimezone"
                                    ></multi-select>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nPassword"
                                    label-for="modal-password"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_password_length')"
                                >
                                    <b-form-input
                                        id="modal-password"
                                        v-model="tenantModal.password"
                                        class="input-pdv-blue"
                                        name="password"
                                        aria-describedby="input-1-live-feedback"
                                        type="password"
                                        :state="validateField('password', 'tenantModal')"
                                        :placeholder="i18nPassword"
                                        :disabled="isTenantCreated || submitLoading"
                                        autocomplete="new-password"
                                        @input="clearResponseError('password', 'tenantModal')"
                                        @blur="$v.tenantModal.password.$touch;"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nPasswordConfirmation"
                                    label-for="modal-password-confirmation"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_password_match')"
                                >
                                    <b-form-input
                                        id="modal-password-confirmation"
                                        v-model="tenantModal.password_confirmation"
                                        class="input-pdv-blue"
                                        name="password"
                                        aria-describedby="input-1-live-feedback"
                                        type="password"
                                        :state="validateField('password_confirmation', 'tenantModal')"
                                        :placeholder="i18nPasswordConfirmation"
                                        :disabled="isTenantCreated || submitLoading"
                                        autocomplete="new-password"
                                        @input="clearResponseError('password_confirmation', 'tenantModal')"
                                        @blur="$v.tenantModal.password_confirmation.$touch;"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col v-if="!isEdit && isTenantCreated && !submitLoading" md="4" class="mt-1 align-self-center">
                                <b-button
                                    class="col-sm-5 btn btn-pdv-gradient-gray"
                                    @click="openModalToChangePassword"
                                    >{{ `${getI18n('COMMON.update')} ${getI18n('COMMON.password')}` }}
                                </b-button>
                            </b-col>
                        </b-form-row>
                        <topic-header
                            :caption="getI18n('TENANT', 'TITLES.configurations')"
                        />
                        <b-form-row>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nIsAddPdv"
                                    label-for="modal-is-add-pdv"
                                    class="label-pdv"
                                    label-align="center"
                                >
                                    <b-form-checkbox
                                        id="modal-is-add-pdv"
                                        v-model="tenantModal.is_add_pdv"
                                        name="is_add_pdv"
                                        class="text-center"
                                        switch
                                        size="lg"
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nIsAlterPasswordPermission"
                                    label-for="modal-is-alter-password-permission"
                                    class="label-pdv"
                                    label-align="center"
                                >
                                    <b-form-checkbox
                                        id="modal-is-alter-password-permission"
                                        v-model="tenantModal.is_alter_password_and_permissions"
                                        name="is_alter_password_and_permissions"
                                        class="text-center"
                                        switch
                                        size="lg"
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nIsGeotagUser"
                                    label-for="modal-is-geotag-user"
                                    class="label-pdv"
                                    label-align="center"
                                >
                                    <b-form-checkbox
                                        id="modal-is-geotag-user"
                                        v-model="tenantModal.is_geotag_user"
                                        name="is_geotag_user"
                                        class="text-center"
                                        switch
                                        size="lg"
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nIsInterval"
                                    label-for="modal-is-interval"
                                    class="label-pdv"
                                    label-align="center"
                                >
                                    <b-form-checkbox
                                        id="modal-is-interval"
                                        v-model="tenantModal.is_interval"
                                        name="is_interval"
                                        class="text-center"
                                        switch
                                        size="lg"
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">

                            </b-col>
                        </b-form-row>
                        <b-form-row>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nIsAddCompetitorToMix"
                                    label-for="modal-is-add-competitor-to-mix"
                                    class="label-pdv"
                                    label-align="center"
                                >
                                    <b-form-checkbox
                                        id="modal-is-add-competitor-to-mix"
                                        v-model="tenantModal.is_add_competitor_to_mix"
                                        name="is_add_competitor_to_mix"
                                        class="text-center"
                                        switch
                                        size="lg"
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nIsAddPdvToBusinessUnit"
                                    label-for="modal-is-add-pdv-to-business-unit"
                                    class="label-pdv"
                                    label-align="center"
                                >
                                    <b-form-checkbox
                                        id="modal-is-add-pdv-to-business-unit"
                                        v-model="tenantModal.is_add_pdv_to_business_unit"
                                        name="is_add_pdv_to_business_unit"
                                        class="text-center"
                                        switch
                                        size="lg"
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nIsAddUserToBusinessUnit"
                                    label-for="modal-is-add-user-to-business-unit"
                                    class="label-pdv"
                                    label-align="center"
                                >
                                    <b-form-checkbox
                                        id="modal-is-add-user-to-business-unit"
                                        v-model="tenantModal.is_add_user_to_business_unit"
                                        name="is_add_user_to_business_unit"
                                        class="text-center"
                                        switch
                                        size="lg"
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nIsRequiredCheckin"
                                    label-for="modal-is-required-checkin"
                                    class="label-pdv"
                                    label-align="center"
                                >
                                    <b-form-checkbox
                                        id="modal-is-required-checkin"
                                        v-model="tenantModal.is_required_checkin"
                                        name="is_required_checkin"
                                        class="text-center"
                                        switch
                                        size="lg"
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <b-form-row>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nIsPhotoCheckin"
                                    label-for="modal-is-photo-checkin"
                                    class="label-pdv"
                                    label-align="center"
                                >
                                    <b-form-checkbox
                                        id="modal-is-photo-checkin"
                                        v-model="tenantModal.is_photo_checkin"
                                        name="is_photo_checkin"
                                        class="text-center"
                                        switch
                                        size="lg"
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nIsMinimumDistanceCheckin"
                                    label-for="modal-is-minimum-distance-checkin"
                                    class="label-pdv"
                                    label-align="center"
                                >
                                    <b-form-checkbox
                                        id="modal-is-minimum-distance-checkin"
                                        v-model="tenantModal.is_minimum_distance_checkin"
                                        name="is_minimum_distance_checkin"
                                        class="text-center"
                                        switch
                                        size="lg"
                                        :disabled="!isEdit || submitLoading"
                                        @input="onChangeIsMinimumDistanceCheckin"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nIsPhotoCheckout"
                                    label-for="modal-is-photo-checkout"
                                    class="label-pdv"
                                    label-align="center"
                                >
                                    <b-form-checkbox
                                        id="modal-is-photo-checkout"
                                        v-model="tenantModal.is_photo_checkout"
                                        name="is_photo_checkout"
                                        class="text-center"
                                        switch
                                        size="lg"
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nIsMinimumDistanceCheckout"
                                    label-for="modal-is-minimum-distance-checkout"
                                    class="label-pdv"
                                    label-align="center"
                                >
                                    <b-form-checkbox
                                        id="modal-is-minimum-distance-checkout"
                                        v-model="tenantModal.is_minimum_distance_checkout"
                                        name="is_minimum_distance_checkout"
                                        class="text-center"
                                        switch
                                        size="lg"
                                        :disabled="!isEdit || submitLoading"
                                        @input="onChangeIsMinimumDistanceCheckout"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <b-form-row>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nShowOutOfStockBtn"
                                    label-for="modal-show-out-of-stock-btn"
                                    class="label-pdv"
                                    label-align="center"
                                >
                                    <b-form-checkbox
                                        id="modal-show-out-of-stock-btn"
                                        v-model="tenantModal.out_of_stock"
                                        name="out_of_stock"
                                        class="text-center"
                                        switch
                                        size="lg"
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nShowNewPosBtn"
                                    label-for="modal-show-new-pos-btn"
                                    class="label-pdv"
                                    label-align="center"
                                >
                                    <b-form-checkbox
                                        id="modal-show-new-pos-btn"
                                        v-model="tenantModal.new_pos_btn"
                                        name="new_pos_btn"
                                        class="text-center"
                                        switch
                                        size="lg"
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <b-form-row>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nMinDistanceCheckin"
                                    label-for="modal-minimum-distance-checkin"
                                    class="label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_distance')"
                                >
                                    <b-form-input
                                        id="modal-minimum-distance-checkin"
                                        v-model.number="tenantModal.minimum_distance_checkin"
                                        class="input-pdv-blue"
                                        name="minimum-distance-checkin"
                                        aria-describedby="input-1-live-feedback"
                                        :disabled="!tenantModal.is_minimum_distance_checkin || !isEdit || submitLoading"
                                        min="0"
                                        type="number"
                                        :state="validateField('minimum_distance_checkin', 'tenantModal')"
                                        @blur="$v.tenantModal.minimum_distance_checkin.$touch;"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nMinDistanceCheckout"
                                    label-for="modal-minimum-distance-checkout"
                                    class="label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_distance')"
                                >
                                    <b-form-input
                                        id="modal-minimum-distance-checkout"
                                        v-model.number="tenantModal.minimum_distance_checkout"
                                        class="input-pdv-blue"
                                        name="minimum-distance-checkout"
                                        aria-describedby="input-1-live-feedback"
                                        type="number"
                                        :disabled="!tenantModal.is_minimum_distance_checkout || !isEdit || submitLoading"
                                        min="0"
                                        :state="validateField('minimum_distance_checkout', 'tenantModal')"
                                        @blur="$v.tenantModal.minimum_distance_checkout.$touch;"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="i18nMinTimeStore"
                                    label-for="modal-minimum-time-store"
                                    class="label-pdv"
                                >
                                    <b-form-input
                                        id="modal-minimum-time-store"
                                        v-model.number="tenantModal.minimum_time_store"
                                        class="input-pdv-blue"
                                        name="minimum-time-store"
                                        aria-describedby="input-1-live-feedback"
                                        type="number"
                                        min="0"
                                        :disabled="!isEdit || submitLoading"
                                        :state="validateField('minimum_time_store', 'tenantModal')"
                                        @blur="$v.tenantModal.minimum_time_store.$touch;"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-tab>
                    <b-tab v-if="isTenantCreated" :title="i18nUsersBusinessUnit">
                        <api-mode-vuetable
                            ref="vuetable"
                            :fetch-data="fetch"
                            :fields="fields"
                            :per-page="10"
                            :additional-parameters="additionalParameters">
                            <template
                                slot="actions"
                                slot-scope="props">
                                <div class="table-button-container">
                                    <more-table-button @onClick="openModalUser(props.rowData)" />
                                </div>
                            </template>
                        </api-mode-vuetable>
                    </b-tab>
                </b-tabs>
            </form>
        </template>
        <template v-if="tabIndex === 0" v-slot:footer>
            <div v-if="isEdit">
                <clean-button
                    v-if="!isTenantCreated"
                    ref="cleanButton"
                    child-class="col-sm-2 float-left"
                    :busy="submitLoading"
                    @onClick="cleanModal"
                />
                <save-button
                    v-if="isTenantCreated"
                    ref="okButton"
                    child-class="col-sm-2 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
                <add-button
                    v-else
                    ref="okButton"
                    child-class="col-sm-2 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-2 float-right"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
            </div>
            <div v-else>
                <back-button
                    ref="backButton"
                    child-class="col-sm-2 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <save-button
                    ref="okButton"
                    child-class="col-sm-2 float-right"
                    :busy="submitLoading"
                    :title="getI18n('COMMON.edit')"
                    @onClick="isEdit = true"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>

import FormModal from '@components/FormModal'
import {
    required,
    requiredIf,
    integer,
    minLength,
    maxLength,
    minValue,
    sameAs,
} from 'vuelidate/lib/validators'
import validationMixin from '@src/mixins/validation'
import swalFeedback from '@src/mixins/swalFeedback'
import TenantService from '@src/services/TenantService'
import UsersService from '@src/services/admin/UsersService'
import timezones from '@assets/resources/timezones'
import multiSelect from '@components/multiSelect'
import TopicHeader from '@src/components/TopicHeader.vue'
import tenantChangePasswordModal from '@src/router/views/tenant/tenantChangePasswordModal'
import tenantUserModal from '@src/router/views/tenant/tenantUserModal'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import BackButton from '@/src/components/BackButton'
import CancelButton from '@/src/components/CancelButton'
import CleanButton from '@/src/components/CleanButton'
import AddButton from '@/src/components/AddButton'
import SaveButton from '@/src/components/SaveButton'
import ApiModeVuetable from '@src/components/ApiModeVuetable'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import vuetableFormatters from '@src/mixins/vuetableFormatters'

const i18nCommon = 'COMMON'
const i18nKey = 'TENANT'

export default {
    components: {
        FormModal,
        multiSelect,
        TopicHeader,
        tenantChangePasswordModal,
        tenantUserModal,
        ConfirmationFormModal,
        BackButton,
        CancelButton,
        CleanButton,
        AddButton,
        SaveButton,
        ApiModeVuetable,
        MoreTableButton,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        vuetableFormatters
    ],
    props: {
        modalIsEdit: {
            required: true,
            type: Boolean,
        },
        modal: {
            type: Object,
            default: () => {
                return {
                    id: 0,
                    name: '',
                    limit_units: 0,
                    limit_users: 0,
                    base_day: 1,
                    uuid: '',
                    default_timezone: '',
                    is_photo_checkin: false,
                    is_photo_checkout: false,
                    is_add_pdv: false,
                    is_add_pdv_to_business_unit: false,
                    is_add_user_to_business_unit: false,
                    is_minimum_distance_checkin: false,
                    minimum_distance_checkin: 0,
                    is_minimum_distance_checkout: false,
                    minimum_distance_checkout: 0,
                    is_geotag_user: false,
                    is_interval: false,
                    is_chat: false,
                    is_required_checkin: false,
                    admin_email: '',
                    password: '',
                    password_confirmation: '',
                    timezone: '',
                    minimum_time_store: 0,
                    is_alter_password_and_permissions: false,
                    is_add_competitor_to_mix: false,
                    out_of_stock: false,
                    new_pos_btn: false,
                }
            },
        },
    },
    data() {
        return {
            i18nCommon,
            i18nKey,
            timezones,
            tenantModal: this.modal,
            isEdit: false,
            submitLoading: false,
            tabIndex: 0,
            timezone: null,
            additionalParameters: {
                tenant_id: 0,
            },
            changePasswordModal: {
                id: 0,
                email: '',
                password: '',
                password_confirmation: '',
            },
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            fields: [
                {
                    name: 'name',
                    title: this.getI18n(i18nCommon, 'name'),
                    sortField: 'name',
                },
                {
                    name: 'email',
                    title: this.getI18n(i18nCommon, 'email'),
                    sortField: 'email',
                },
                {
                    name: 'active',
                    title: this.getI18n(i18nCommon, 'active'),
                    sortField: 'active',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'cost_of_serving_module',
                    title: this.getI18n('BUSINESS_UNITS', 'TITLES.serving_cost_module'),
                    sortField: 'cost_of_serving_module',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'commercial_module',
                    title: this.getI18n('BUSINESS_UNITS', 'TITLES.commercial_module'),
                    sortField: 'commercial_module',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'actions',
                    title: '',
                },
            ]
        }
    },
    validations: {
        tenantModal: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(255),
            },
            minimum_distance_checkin: {
                integer,
                minValue(value, model) {
                    if (!model.is_minimum_distance_checkin) {
                        return true
                    }
                    return value > 0
                },
            },
            minimum_distance_checkout: {
                integer,
                minValue(value, model) {
                    if (!model.is_minimum_distance_checkout) {
                        return true
                    }
                    return value > 0
                },
            },
            minimum_time_store: {
                integer,
                minValue: minValue(0),
            },
            limit_units: {
                integer,
                minValue: minValue(0),
            },
            limit_users: {
                integer,
                minValue: minValue(0),
            },
            base_day: {
                integer,
                minValue: minValue(0),
            },
            admin_email: {
                required: requiredIf(function (modal) {
                    return !this.isTenantCreated
                }),
            },
            password: {
                required: requiredIf(function (modal) {
                    return !this.isTenantCreated
                }),
                minLength: minLength(6),
            },
            password_confirmation: {
                required: requiredIf(function (modal) {
                    return !this.isTenantCreated
                }),
                sameAsPassword: sameAs('password'),
            },
        },
        timezone: {
            required,
        },
    },
    computed: {
        isTenantCreated() {
            return this.tenantModal.id > 0
        },
        i18nTitleModal() {
            if (this.isTenantCreated) {
                if (this.isEdit) {
                    return `${this.getI18n(i18nCommon, 'edit')} ${this.i18nTenant}`
                }
                return this.tenantModal.name
            }
            else {
                return this.getI18n(i18nKey, 'BUTTONS.new_tenant')
            }
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nLimitUnits() {
            return this.getI18n(i18nKey, 'FIELDS.limit_units')
        },
        i18nLimitUsers() {
            return this.getI18n(i18nKey, 'FIELDS.limit_users')
        },
        i18nBaseDay() {
            return this.getI18n(i18nKey, 'FIELDS.base_day')
        },
        i18nEmail() {
            return this.getI18n(i18nCommon, 'email')
        },
        i18nPassword() {
            return this.getI18n(i18nCommon, 'password')
        },
        i18nPasswordConfirmation() {
            return this.getI18n(i18nCommon, 'password_confirmation')
        },
        i18nTimezone() {
            return this.getI18n(i18nKey, 'FIELDS.default_timezone')
        },
        i18nIsPhotoCheckin() {
            return this.getI18n(i18nKey, 'FIELDS.is_photo_checkin')
        },
        i18nIsPhotoCheckout() {
            return this.getI18n(i18nKey, 'FIELDS.is_photo_checkout')
        },
        i18nIsAddPdv() {
            return this.getI18n(i18nKey, 'FIELDS.is_add_pdv')
        },
        i18nIsAddPdvToBusinessUnit() {
            return this.getI18n(i18nKey, 'FIELDS.is_add_pdv_to_business_unit')
        },
        i18nIsAddUserToBusinessUnit() {
            return this.getI18n(i18nKey, 'FIELDS.is_add_user_to_business_unit')
        },
        i18nIsAlterPasswordPermission() {
            return this.getI18n(i18nKey, 'FIELDS.is_alter_password_and_permissions')
        },
        i18nIsAddCompetitorToMix() {
            return this.getI18n(i18nKey, 'FIELDS.is_add_competitor_to_mix')
        },
        i18nIsMinimumDistanceCheckin() {
            return this.getI18n(i18nKey, 'FIELDS.is_minimum_distance_checkin')
        },
        i18nMinDistanceCheckin() {
            return this.getI18n(i18nKey, 'FIELDS.minimum_distance_checkin')
        },
        i18nIsMinimumDistanceCheckout() {
            return this.getI18n(i18nKey, 'FIELDS.is_minimum_distance_checkout')
        },
        i18nMinDistanceCheckout() {
            return this.getI18n(i18nKey, 'FIELDS.minimum_distance_checkout')
        },
        i18nIsGeotagUser() {
            return this.getI18n(i18nKey, 'FIELDS.is_geotag_user')
        },
        i18nIsInterval() {
            return this.getI18n(i18nKey, 'FIELDS.is_interval')
        },
        i18nIsChat() {
            return this.getI18n(i18nKey, 'FIELDS.is_chat')
        },
        i18nIsRequiredCheckin() {
            return this.getI18n(i18nKey, 'FIELDS.is_required_checkin')
        },
        i18nMinTimeStore() {
            return this.getI18n(i18nKey, 'FIELDS.minimum_time_store')
        },
        i18nTenant() {
            return this.getI18n(i18nKey, 'TITLES.tenant')
        },
        modalOperation() {
            return this.isTenantCreated ? 'edited' : 'created'
        },
        isMinimumDistanceCheckin() {
            return this.is_minimum_distance_checkin
        },
        isMinimumDistanceCheckout() {
            return this.is_minimum_distance_checkout
        },
        i18nInvalidName() {
            return this.getI18n('ERROR_CODES.invalid_name')
        },
        i18nInformation() {
            return this.getI18nModified({
                prefix: 'COMMON.info',
                modifier: 2,
            })
        },
        i18nUsers() {
            return this.getI18nModified({
                prefix: 'USERS',
                suffix: 'TITLES.user',
                modifier: 2,
            })
        },
        i18nUsersBusinessUnit() {
            return `${this.i18nUsers} (${this.getI18n('COMMON.business_unit')})`
        },
        i18nShowOutOfStockBtn() {
            return this.getI18n(i18nKey, 'FIELDS.show_out_of_stock_btn')
        },
        i18nShowNewPosBtn() {
            return this.getI18n(i18nKey, 'FIELDS.show_new_pos_btn')
        },
    },
    methods: {
        changeTimezone(option) {
            this.tenantModal.default_timezone = option.id
        },
        showModal() {
            this.isEdit = this.modalIsEdit
            this.submitLoading = false
            this.tabIndex = 0
            this.tenantModal = this.modal
            this.additionalParameters = {
                tenant_id: this.tenantModal.id,
            }

            this.$refs.formModal.show()
            this.resetValidation()
        },

        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
        },

        cleanModal() {
            this.replaceModalData({
                id: 0,
                name: '',
                limit_units: 0,
                limit_users: 0,
                base_day: 1,
                uuid: '',
                default_timezone: '',
                is_photo_checkin: false,
                is_photo_checkout: false,
                is_add_pdv: false,
                is_add_pdv_to_business_unit: false,
                is_add_user_to_business_unit: false,
                is_minimum_distance_checkin: false,
                minimum_distance_checkin: 0,
                is_minimum_distance_checkout: false,
                minimum_distance_checkout: 0,
                is_geotag_user: false,
                is_interval: false,
                is_chat: false,
                is_required_checkin: false,
                admin_email: '',
                password: '',
                password_confirmation: '',
                timezone: '',
                minimum_time_store: 0,
                is_alter_password_and_permissions: false,
                is_add_competitor_to_mix: false,
                out_of_stock: false,
                new_pos_btn: false,
            })
            this.resetValidation()
        },

        replaceModalData(data) {
            Object.assign(this.tenantModal, data)
            if (this.tenantModal.id > 0) {
                this.tenantModal.password = 'ops... não foi dessa vez!'
                this.tenantModal.password_confirmation = this.tenantModal.password
                this.tenantModal.base_day = this.tenantModal.base_day > 0 ? this.tenantModal.base_day : 1
            }

            this.timezone = timezones.find(
                (timezone) => timezone.id === data.default_timezone
            )
        },

        openModalToChangePassword() {
            Object.assign(this.changePasswordModal, {
                id: this.tenantModal.id,
                email: this.tenantModal.admin_email,
                password: '',
                password_confirmation: ''
            })
            this.$refs.tenantChangePasswordModal.showModal()
        },

        openModalUser(data) {
            this.$refs.tenantUserModal.showModal(data)
        },

        handleCancel(event) {
            if (this.isEdit) {
                this.$refs.confirmationFormModal.showModal(true, this.handleClose)
            }
            else {
                this.handleClose(true)
            }
        },

        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },

        handleOk() {
            this.$v.tenantModal.$touch()
            if (!this.$v.tenantModal.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },
        createOrUpdateTenant() {
            if (!this.isTenantCreated) {
                this.tenantModal.tenant_admin = {
                    email: this.tenantModal.admin_email,
                    name: this.tenantModal.name,
                    password: this.tenantModal.password,
                    password_confirmation: this.tenantModal.password_confirmation,
                    timezone: this.tenantModal.default_timezone,
                }
            }
            const operation = this.isTenantCreated
                ? TenantService.update(this.tenantModal.id, this.tenantModal)
                : TenantService.create(this.tenantModal)

            return operation
                .then((response) => response)
                .catch((error) => error)
        },

        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            const operation = this.isTenantCreated ? 'edited' : 'created'

            const response = await this.createOrUpdateTenant()

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.successfulOperation(operation)
                this.$refs.formModal.hide()
            } else if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
                this.warningFeedbackApi(this.i18nTenant, response.data.errors)
            }

            this.submitLoading = false
        },
        successfulOperation(operation) {
            this.$emit('refresh')
            this.resetValidation()
            this.positiveFeedback(this.i18nTenant, operation)
        },
        unsuccessfulOperation() {
            this.negativeFeedback()
        },
        onChangeIsMinimumDistanceCheckin(checked) {
            if (!checked) {
                this.tenantModal.minimum_distance_checkin = 0
            }
        },
        onChangeIsMinimumDistanceCheckout(checked) {
            if (!checked) {
                this.tenantModal.minimum_distance_checkout = 0
            }
        },
        fetch(url, params) {
            return UsersService.fetchVuetable(url, params)
        },
    },
}
</script>
